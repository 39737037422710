<template>
  <div class="food-list-page w-100">
    <basic-subheader>
      <template v-slot:actions>
        <b-dropdown
          id="dropdown-right dropdown-form"
          class="dropdown-form-filter mr-2"
          no-caret
          right
        >
          <template #button-content>
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </template>
          <h6 class="d-flex align-items-center mb-0 p-4">
            <span class="svg-icon mr-3">
              <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
            </span>
            Bộ lọc
          </h6>
          <b-dropdown-divider> </b-dropdown-divider>
          <b-dropdown-form class="mw-400 form-filter">
            <b-container class="p-0">
              <b-row>
                <b-col>
                  <basic-select
                    label="KCAL"
                    placeholder="--- Chọn số KCAL ---"
                    name="kCal"
                    :solid="false"
                    multiple
                    changeValueByObject
                    trackBy="value"
                    valueLabel="name"
                    :value.sync="filters.kCals"
                    :options="kCalList"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-select
                    label="Số bữa phụ"
                    placeholder="--- Chọn số bữa phụ ---"
                    name="noOfSnackTime"
                    :solid="false"
                    changeValueByObject
                    trackBy="value"
                    valueLabel="name"
                    multiple
                    :value.sync="filters.noOfSnackTimes"
                    :options="snackOptions"
                  />
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <basic-select
                    label="Trạng thái"
                    placeholder="--- Chọn trạng thái ---"
                    name="status"
                    :solid="false"
                    changeValueByObject
                    trackBy="value"
                    valueLabel="name"
                    :options="statuses"
                    :value.sync="filters.status"
                  />
                </b-col>
              </b-row>
            </b-container>
          </b-dropdown-form>
          <b-dropdown-divider> </b-dropdown-divider>
          <div class="d-flex align-items-center justify-content-lg-end m-0 p-4">
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click.stop="resetRequest"
            >
              <span class="svg-icon">
                <inline-svg
                  src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                />
              </span>
              Reset bộ lọc
            </b-button>
            <b-button
              class="btn ml-2"
              href="#"
              tabindex="0"
              @click="searchRequest"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/filter.svg" />
              </span>
              Lọc dữ liệu
            </b-button>
          </div>
        </b-dropdown>
        <b-button
          v-if="isWritePermission"
          class="btn btn-success"
          type="button"
          @click.stop="createMenu"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
          </span>
          Thêm thực đơn mãu
        </b-button>
      </template>
    </basic-subheader>
    <Wrapper>
      <b-overlay :show="loading">
        <v-data-table
          :headers="headers"
          :items="menuList"
          item-key="id"
          group-by="kCal"
          :items-per-page="-1"
          class="menu-list"
          hide-default-footer
          calculate-widths
        >
          <template v-slot:group.header="{ toggle, group, isOpen }">
            <td :colspan="headers.length" class="pl-0 no-background">
              <div class="d-flex align-items-center">
                <v-btn @click="toggle" :ref="group" :data-open="isOpen" icon>
                  <v-icon
                    >mdi-{{ isOpen ? 'chevron-down' : 'chevron-up' }}</v-icon
                  >
                </v-btn>
                <span class="font-weight-boldest">{{ group }}</span>
              </div>
            </td>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.code }}</td>
              <td>{{ item.menuTitle }}</td>
              <!-- <td>{{ item.kCal }}</td> -->
              <td class="text-center">{{ item.noOfSnackTime }}</td>
              <td class="text-center">{{ getStatus(item.status) }}</td>
              <td>
                <span>
                  <action-dropdown
                    :value="item"
                    :show_copy="false"
                    :show_view="false"
                    :show_edit="false"
                    :show_delete="!showBtnActive(item) && isWritePermission"
                    :boundary="menuList.length > 2 ? 'scrollParent' : 'window'"
                    @delete="showPopupChangeStatus(item.id, false)"
                  >
                    <b-dropdown-text
                      tag="div"
                      class="navi-item cursor-pointer"
                      @click="viewMenuFood(item)"
                    >
                      <a class="navi-link text-primary">
                        <span class="menu-icon svg-icon svg-icon-sm">
                          <inline-svg
                            class="svg-icon"
                            src="/media/svg/icons/Neolex/Basic/eye.svg"
                          />
                        </span>
                        <span class="navi-text text-primary"
                          >Xem chi tiết thực đơn</span
                        >
                      </a>
                    </b-dropdown-text>
                    <b-dropdown-divider v-if="isWritePermission" />
                    <template v-if="isWritePermission">
                      <b-dropdown-text
                        tag="div"
                        class="navi-item cursor-pointer"
                      >
                        <a
                          class="navi-link text-primary"
                          @click.stop="editMenuFood(item)"
                        >
                          <span class="menu-icon svg-icon svg-icon-sm">
                            <inline-svg
                              class="svg-icon"
                              src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                            />
                          </span>
                          <span class="navi-text text-primary"
                            >Chỉnh sửa thực đơn</span
                          >
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-divider />
                      <b-dropdown-text
                        tag="div"
                        class="navi-item cursor-pointer"
                      >
                        <a
                          class="navi-link text-primary"
                          @click.stop="copyMenuFood(item)"
                        >
                          <span class="menu-icon svg-icon svg-icon-sm">
                            <inline-svg
                              class="svg-icon"
                              src="/media/svg/icons/Neolex/Basic/copy.svg"
                            />
                          </span>
                          <span class="navi-text text-primary">Copy</span>
                        </a>
                      </b-dropdown-text>
                      <b-dropdown-divider />
                      <b-dropdown-text
                        v-if="showBtnActive(item)"
                        tag="div"
                        class="navi-item cursor-pointer"
                        @click="showPopupChangeStatus(item.id, true)"
                      >
                        <a class="navi-link text-primary">
                          <span class="menu-icon svg-icon svg-icon-sm">
                            <inline-svg
                              class="svg-icon"
                              src="/media/svg/icons/Neolex/Basic/power.svg"
                            />
                          </span>
                          <span class="navi-text text-primary">Active</span>
                        </a>
                      </b-dropdown-text>
                    </template>
                  </action-dropdown>
                </span>
              </td>
              <td class="text-center">
                {{ $moment(item.updateDatetime * 1000).format('DD/MM/YYYY') }}
              </td>
              <td>
                <div class="d-flex align-items-center p-4">
                  <avatar
                    size="40px"
                    :text="item.updaterName"
                    :src="item.avatar"
                    :rounded="true"
                  />
                  <div class="d-flex flex-column ml-5">
                    <p class="mb-0 module-list-page__body__updater-name">
                      {{ item.updaterName }}
                    </p>
                    <p class="mt-2 mb-0 module-list-page__body__updater-code">
                      {{ item.updaterAccountName }}
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </v-data-table>
      </b-overlay>
      <modal-menu :id="menuId" :type="typeModal" @cancel="hiddenModal" />
    </Wrapper>
  </div>
</template>
<script>
import Modal from './components/Modal.vue';
import { createNamespacedHelpers } from 'vuex';
import { CHANGE_STATUS } from '@/core/services/store/menu.module';
const { mapActions } = createNamespacedHelpers('menu');
export default {
  name: 'MenuList',
  components: {
    'modal-menu': Modal,
  },
  data() {
    return {
      typeModal: null,
      filters: {
        kCals: null,
        status: null,
        noOfSnackTimes: null,
      },
      loading: false,
      menuList: [],
      kCalList: [
        {
          name: '1000',
          value: 1000,
        },
        {
          name: '1200',
          value: 1200,
        },
        {
          name: '1400',
          value: 1400,
        },
        {
          name: '1600',
          value: 1600,
        },
        {
          name: '1800',
          value: 1800,
        },
        {
          name: '2000',
          value: 2000,
        },
        {
          name: '2200',
          value: 2200,
        },
        {
          name: '2400',
          value: 2400,
        },
        {
          name: '2600',
          value: 2600,
        },
      ],
      menuId: null,
      snackOptions: [
        {
          name: '0',
          value: 0,
        },
        {
          name: '1',
          value: 1,
        },
        {
          name: '2',
          value: 2,
        },
        {
          name: '3',
          value: 3,
        },
      ],
      statuses: [
        {
          name: 'Active',
          value: '1',
        },
        {
          name: 'Inactive',
          value: '0',
        },
        {
          name: 'Draft',
          value: '2',
        },
      ],
      headers: [
        {
          text: 'Mã',
          align: 'left',
          value: 'code',
          sortable: false,
        },
        {
          text: 'Tên thực đơn',
          value: 'menuTitle',
          sortable: false,
          align: 'left',
        },
        {
          text: 'Số bữa phụ',
          value: 'noOfSnackTime',
          sortable: false,
          align: 'center',
        },
        {
          text: 'Trạng thái',
          value: 'status',
          sortable: false,
          align: 'center',
        },
        { text: '', value: null, sortable: false, width: '1%' },
        {
          text: 'Cập nhật lần cuối',
          value: null,
          sortable: false,
          align: 'center',
        },
        {
          text: 'Người cập nhật',
          value: null,
          sortable: false,
          align: 'left',
        },
      ],
    };
  },
  computed: {
    searchParams() {
      const params = new URLSearchParams();
      params.append('status', this.filters.status?.value || '-1');
      if (this.filters.kCals?.length) {
        this.filters.kCals.forEach((el) => {
          params.append('kCals', el.value);
        });
      }
      if (this.filters.noOfSnackTimes?.length) {
        this.filters.noOfSnackTimes.forEach((el) => {
          params.append('noOfSnackTimes', el.value);
        });
      }
      return params;
    },
    isWritePermission() {
      return this.$route.meta.isWritePermission;
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    ...mapActions({ CHANGE_STATUS }),
    loadData() {
      this.getMenuList();
    },
    async getMenuList() {
      this.loading = true;
      try {
        let { items } = await this.$api.get('/FoodMenu', {
          params: this.searchParams,
        });
        this.menuList = items;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    searchRequest() {
      this.getMenuList();
      document.body.click();
    },
    createMenu() {
      this.typeModal = 'create';
      this.$bvModal.show('menu-modal');
    },
    hiddenModal() {
      this.typeModal = null;
      this.menuId = null;
      this.$bvModal.hide('menu-modal');
    },
    resetRequest() {
      this.filters = {
        kCal: null,
        status: '-1',
        noOfSnackTimes: null,
        page: 1,
        size: 10,
      };
      this.getMenuList();
      document.body.click();
    },
    viewMenuFood(params) {
      this.$router.push({
        name: 'menu_detail',
        params: {
          id: params.id,
        },
      });
    },
    editMenuFood(params) {
      this.$router.push({
        name: 'menu_edit',
        params: {
          id: params.id,
        },
      });
    },
    copyMenuFood(params) {
      this.menuId = params.id;
      this.typeModal = 'copy';
      this.$bvModal.show('menu-modal');
    },
    async deleteMenuFood(params) {
      this.loading = true;
      try {
        await this.$api.delete(`/FoodMenu/Input/${params.id}`);
        this.loadData();
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async changeStatus(id, isActive) {
      this.loading = true;
      const params = {
        id,
        isActive,
      };
      const { error } = await this.CHANGE_STATUS(params);
      if (!error) {
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
        this.loadData();
      } else {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error.message,
        });
      }
      this.loading = false;
    },
    showBtnActive(item) {
      if ([0, 2].includes(item.status)) return true;
      return false;
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return 'Inactive';
        case 1:
          return 'Active';

        default:
          return 'Draft';
      }
    },
    showPopupChangeStatus(id, isActive) {
      this.$swal({
        title: 'Xác nhận',
        text: `Bạn có chắc chắn muốn chuyển trạng thái thành ${
          isActive ? 'active' : 'inactive'
        } không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ bỏ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            // inactive all
            this.changeStatus(id, isActive);
          }
        }.bind(this),
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.mw-400 {
  min-width: 400px;
}
</style>
